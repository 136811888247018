import styled from "styled-components"

const Container = styled.div`
  background: #ffffff;
  border-radius: 20px;
  padding: 36px 32px 10px;

  > .cont-header {
    align-items: center;
    display: flex;
    margin-bottom: 10px;

    p {
      color: #727272;
      font-size: 10px;
    }

    hr {
      border: none;
      border-top: 1px solid #c5c5c5;
      width: 100%;
    }

    img {
      margin-right: 20px;
      height: 11px;
    }
  }

  @media (max-width: 768px) {
    padding: 24px 16px 10px;

    > .cont-header img {
      margin: 0 12px !important;
    }
  }
`

export const SupportedByStyle = styled(Container)`
  margin-right: 18px;
  text-align: center;

  > div:last-child {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100% - 19px);
  }

  @media (max-width: 768px) {
    margin-right: 0;
    margin-bottom: 16px;

    > div:last-child {
      margin: 12px 0 6px;
    }
  }
`

export const SponsorsStyle = styled(Container)`
  width: 100%;

  hr:first-child {
    display: none;
  }

  .top-row,
  .bot-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .top-row {
    margin: 0 -15px;
    a {
      margin: 20px 10px;
    }
  }

  .bot-row {
    margin: 0 -10px;
    a {
      margin: 15px 10px;
    }
  }

  @media (max-width: 768px) {
    width: auto;

    hr:first-child {
      display: block;
    }

    .top-row {
      margin: 0 0;
      a {
        margin: 12px 15px;
      }
    }

    .bot-row {
      margin: 0 -10px;
      a {
        margin: 8px 10px;
      }
    }
  }
`

export const MedparStyle = styled(Container)`
  padding-top: 30px;

  > .cont-header img {
    margin-left: 20px;
  }

  .medpar-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: 0 -5px;

    > div {
      margin: 12px 8px;
    }

    @media (max-width: 768px) {
      > div {
        margin: 8px 6px;
      }

      &:first-child {
        margin-bottom: 4px;
      }

      &:first-child > div {
        margin: 8px 4px;
      }
    }
  }

  .medpar-desktop > .medpar-row:first-child {
    margin: 0 -10px;
  }

  .medpar-mobile {
    display: none;
  }

  @media (max-width: 992px) {
    .medpar-desktop {
      display: none;
    }
    .medpar-mobile {
      display: block;
    }
  }
`

export const SponsorMedparDiv = styled.div`
  margin-bottom: 96px;
  margin-top: 96px;

  > div:first-child {
    display: flex;
    margin-bottom: 24px;
  }

  @media (max-width: 768px) {
    margin-bottom: 48px;

    > div:first-child {
      flex-direction: column;
      margin-bottom: 16px;
    }
  }
`
