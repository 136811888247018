import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */
const Image = ({ imgName, ...props }) => (
  <StaticQuery
    query={graphql`
      query {
        support: allFile(
          filter: {
            relativePath: { regex: "/images/sponsor-medpar/support_/" }
          }
        ) {
          nodes {
            childImageSharp {
              desktop: fixed(height: 97) {
                ...GatsbyImageSharpFixed
                originalName
              }
              mobile: fixed(height: 72) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
        sponsor1: allFile(
          filter: {
            relativePath: { regex: "/images/sponsor-medpar/sponsor1_/" }
          }
        ) {
          nodes {
            childImageSharp {
              desktop: fixed(height: 46) {
                ...GatsbyImageSharpFixed
                originalName
              }
              mobile: fixed(height: 34) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
        sponsor2: allFile(
          filter: {
            relativePath: { regex: "/images/sponsor-medpar/sponsor2_/" }
          }
        ) {
          nodes {
            childImageSharp {
              desktop: fixed(height: 32) {
                ...GatsbyImageSharpFixed
                originalName
              }
              mobile: fixed(height: 24) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
        medpar1: allFile(
          filter: {
            relativePath: { regex: "/images/sponsor-medpar/medpar1_/" }
          }
        ) {
          nodes {
            childImageSharp {
              desktop: fixed(height: 23) {
                ...GatsbyImageSharpFixed
                originalName
              }
              mobile: fixed(height: 17) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
        medpar2: allFile(
          filter: {
            relativePath: { regex: "/images/sponsor-medpar/medpar2_/" }
          }
        ) {
          nodes {
            childImageSharp {
              desktop: fixed(height: 15) {
                ...GatsbyImageSharpFixed
                originalName
              }
            }
          }
        }
        medpar3: allFile(
          filter: {
            relativePath: { regex: "/images/sponsor-medpar/medpar3_/" }
          }
        ) {
          nodes {
            childImageSharp {
              desktop: fixed(height: 25) {
                ...GatsbyImageSharpFixed
                originalName
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      const imgPrefix = imgName.split("_")[0]
      const image = data[imgPrefix].nodes.find(
        (node) => node.childImageSharp.desktop.originalName === imgName
      )
      if (!image) {
        return null
      }

      let sources
      if (image.childImageSharp.mobile) {
        sources = [
          image.childImageSharp.desktop,
          {
            ...image.childImageSharp.mobile,
            media: `(max-width: 768px)`,
          },
        ]
      } else {
        sources = image.childImageSharp.desktop
      }
      return <Img fixed={sources} {...props} />
    }}
  />
)

Image.propTypes = {
  imgName: PropTypes.string,
}

export default Image
