import React from "react"

import {
  SupportedByStyle,
  SponsorsStyle,
  MedparStyle,
  SponsorMedparDiv,
} from "./style"
import Image from "./image"
import textSupported from "./text_supported-by.svg"
import textSponsors from "./text_sponsors.svg"
import textMedpar from "./text_media-partners.svg"

const SupportedBy = () => (
  <SupportedByStyle>
    <div>
      <img src={textSupported} alt="SUPPORTED BY" />
    </div>
    <div>
      <a href="https://www.kominfo.go.id/">
        <Image imgName="support_kominfo.png" />
      </a>
    </div>
  </SupportedByStyle>
)

const sponsor1 = [
  {
    imgName: "tokopedia.png",
    url: "https://www.tokopedia.com",
  },
  {
    imgName: "gojek.png",
    url: "https://www.gojek.com/",
  },
  {
    imgName: "shopee.png",
    url: "https://shopee.co.id/",
  },
  {
    imgName: "mitratel.png",
    url: "http://www.mitratel.co.id/",
  },
  {
    imgName: "bakti.png",
    url: "https://www.baktikominfo.id/",
  },
  {
    imgName: "rsk.png",
    url: "https://www.rumahsiapkerja.com/",
  },
]

const sponsor = [
  {
    imgName: "hk.png",
    url: "https://www.hutamakarya.com/",
  },
  {
    imgName: "dewaweb.png",
    url: "https://www.dewaweb.com/",
  },
  {
    imgName: "bca.png",
    url: "https://www.bca.co.id/en/",
  },
  {
    imgName: "finnet.png",
    url: "https://www.finpay.id/",
  },
  {
    imgName: "mandiri.png",
    url: "https://bankmandiri.co.id/",
  },
  {
    imgName: "aws.png",
    url: "https://aws.amazon.com/",
  },
  {
    imgName: "bni.png",
    url: "https://www.bni.co.id/en-us/",
  },
  {
    imgName: "jne.png",
    url: "https://www.jne.co.id/",
  },
  {
    imgName: "telkom.png",
    url: "https://www.telkom.co.id/",
  },
]

const Sponsors = () => (
  <SponsorsStyle>
    <div className="cont-header">
      <hr />
      <img src={textSponsors} alt="SPONSORS" />
      <hr />
    </div>
    <div className="top-row">
      {sponsor1.map((val) => (
        <a href={val.url} key={val.imgName}>
          <Image imgName={`sponsor1_${val.imgName}`} />
        </a>
      ))}
    </div>
    <div className="bot-row">
      {sponsor.map((val) => (
        <a href={val.url} key={val.imgName}>
          <Image imgName={`sponsor2_${val.imgName}`} />
        </a>
      ))}
    </div>
  </SponsorsStyle>
)

const Medpar1 = () => (
  <>
    <Image imgName="medpar1_kumparan.png" />
    <Image imgName="medpar1_tech-in-asia.png" />
    <Image imgName="medpar1_good-news.png" />
    <Image imgName="medpar1_kampus-update.png" />
    <Image imgName="medpar1_daily-social.png" />
    <Image imgName="medpar1_mave.png" />
    <Image imgName="medpar1_hipwee.png" />
    <Image imgName="medpar1_event-jakarta.png" />
    <Image imgName="medpar1_kreativv-id.png" />
    <Image imgName="medpar1_depok-pos.png" />
    <Image imgName="medpar1_lomba-online.png" />
    <Image imgName="medpar1_heboh.png" />
    <Image imgName="medpar1_logoeternal.png" />
    <Image imgName="medpar1_seputarkuliah.png" />
    <Image imgName="medpar1_bahanaFM.png" />
  </>
)

const Medpar2 = () => (
  <>
    <Image imgName="medpar2_informasievent.png" />
    <Image imgName="medpar2_bitnesia.png" />
    <Image imgName="medpar2_seputar-kampus.png" />
    <Image imgName="medpar2_berisi-id.png" />
    <Image imgName="medpar2_goers.png" />
    <Image imgName="medpar2_progress.png" />
    <Image imgName="medpar2_stui.png" />
    <Image imgName="medpar2_kanal30.png" />
    <Image imgName="medpar2_event-bandung.png" />
    <Image imgName="medpar2_tvui.png" />
    <Image imgName="medpar2_bisnis-indonesia.png" />
    <Image imgName="medpar2_pusat-bazaar.png" />
    <Image imgName="medpar2_gema.png" />
    <Image imgName="medpar2_event-news.png" />
    <Image imgName="medpar2_tekno-event.png" />
    <Image imgName="medpar3_info-seminar-2020.png" />
  </>
)

const Medpar3 = () => (
  <>
    <Image imgName="medpar3_budiluhur.png" />
    <Image imgName="medpar3_mediarga-event.png" />
    <Image imgName="medpar3_pyy.png" />
    <Image imgName="medpar3_media-have-fun.png" />
    <Image imgName="medpar3_teknowlogi.png" />
    <Image imgName="medpar3_lu-kan-anak-it.png" />
    <Image imgName="medpar3_media-event.png" />
    <Image imgName="medpar3_lomba-terkini.png" />
    <Image imgName="medpar3_mahasiswa-indonesia.png" />
    <Image imgName="medpar3_jals.png" />
    <Image imgName="medpar3_event-campus.png" />
    <Image imgName="medpar3_acara-mahasiswa.png" />
    <Image imgName="medpar3_file-magz.png" />
    <Image imgName="medpar3_update-lomba.png" />
    <Image imgName="medpar3_update-lomba-id.png" />
    <Image imgName="medpar3_event-banget.png" />
    <Image imgName="medpar3_yot-depok.png" />
    <Image imgName="medpar3_kitamahasiswa.png" />
    <Image imgName="medpar3_girlkode.png" />
  </>
)

const Medpar = () => (
  <MedparStyle>
    <div className="cont-header">
      <hr />
      <img src={textMedpar} alt="MEDIA PARTNERS" />
      <hr />
    </div>
    <div>
      <div className="medpar-desktop">
        <div className="medpar-row">
          <Medpar1 />
        </div>
        <div className="medpar-row">
          <Medpar2 />
        </div>
        <div className="medpar-row">
          <Medpar3 />
        </div>
      </div>
      <div className="medpar-mobile">
        <div className="medpar-row">
          <Medpar1 />
        </div>
        <div className="medpar-row">
          <Medpar2 />
          <Medpar3 />
        </div>
      </div>
    </div>
  </MedparStyle>
)

export default () => (
  <SponsorMedparDiv>
    <div>
      <SupportedBy />
      <Sponsors />
    </div>
    <Medpar />
  </SponsorMedparDiv>
)
